import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import { useMediaQuery } from "@material-ui/core";
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import FlipNumbers from 'react-flip-numbers';
import { withStyles, useTheme } from '@material-ui/core/styles';
import { Fade } from "react-awesome-reveal";
import Typography from '../components/Typography';

import contactImage from './../../../static/icons/contact.svg';


const styles = (theme) => ({
});

function TeamSection(props) {
  const { classes } = props;
  const theme = useTheme();

  const wrap = useMediaQuery(theme => theme.breakpoints.up("sm")) ? 'nowrap' : 'wrap';

  return (
    <Container>
      <Grid item xs={12} sm={12} md={12}>
        <Fade
          triggerOnce
        >
          <Typography variant="h4" marked="center" align="center" style={{marginTop: 25, marginBottom: 25}}>
            Meet the Team
          </Typography>
        </Fade>
      </Grid>
      {/* MAIN */}
      <Fade
        triggerOnce
        delay={200}
      >
        <Grid container alignItems="center" justify="center" style={{marginTop: 45, maxWidth: '900px', marginLeft: 'auto', marginRight: 'auto'}}>
          <Grid container alignItems="center" justify="center" direction="row" wrap={wrap}>
            <Avatar
              alt="Avatar"
              src="https://www.seekpng.com/png/detail/110-1100707_person-avatar-placeholder.png"
              style={{width: 150, height: 150}}
            />
            <div style={{marginLeft: 20, marginTop: 10}}>
              <div style={{marginBottom: 10}}>
                <Typography variant="h5">S K Loh</Typography>
                <Typography variant="h6">
                  General Manager
                </Typography>
              </div>
              <Typography>
                S K LOH started his foray into the maritime industry as a boarding officer which led him to pick up valuable experience in the ship spares industry. Over the years, he has honed practical skills and knowledge in the shipping industry. This has enabled him to gather and grow a 5 man team to the current 40 man team at Commonwealth Freight Services Pte Ltd, where he serves as the founder and director.
              </Typography>
            </div>
          </Grid>
          <Grid container alignItems="center" justify="center" direction="row" wrap={wrap} style={{marginTop: 30}}>
            <Avatar
              alt="Avatar"
              src="https://www.seekpng.com/png/detail/110-1100707_person-avatar-placeholder.png"
              style={{width: 150, height: 150}}
            />
            <div style={{marginLeft: 20, marginTop: 10}}>
              <div style={{marginBottom: 10}}>
                <Typography variant="h5">Y M Tan</Typography>
                <Typography variant="h6">Business Development Manager</Typography>
              </div>
              <Typography>
                Y M TAN’s wealth of experience in the Maritime industry has allowed him to strategically lead the business and logistical operations for Commonwealth Freight Services Pte Ltd. As a business development manager, he attends and coordinates logistical operations for marine vessels while also forging and maintaining business relationships with clients.
              </Typography>
            </div>
          </Grid>
          <Grid container alignItems="center" justify="center" direction="row" wrap={wrap} style={{marginTop: 30}}>
            <Avatar
              alt="Avatar"
              src="https://www.seekpng.com/png/detail/110-1100707_person-avatar-placeholder.png"
              style={{width: 150, height: 150}}
            />
            <div style={{marginLeft: 20, marginTop: 10}}>
              <div style={{marginBottom: 10}}>
                <Typography variant="h5">Aung K T</Typography>
                <Typography variant="h6">Operations Manager</Typography>
              </div>
              <Typography>
                AUNG K T leads the daily operations at Commonwealth Freight Services Pte Ltd. As an operations manager, he is responsible for overseeing and managing the company’s logistics, transportation and warehousing, all while ensuring that his clients’ needs are met.
              </Typography>
            </div>
          </Grid>
        </Grid>
      </Fade>


    </Container>
  );
}

TeamSection.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(TeamSection);
