import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';
import Chip from '@material-ui/core/Chip';
import { Fade } from "react-awesome-reveal";
import Particles from 'react-particles-js';
import Button from '../components/Button';
import Typography from '../components/Typography';

import { StackedCarousel, ResponsiveContainer, StackedCarouselSlideProps } from 'react-stacked-center-carousel';
import Fab from '@material-ui/core/Fab';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

import contactImage from './../../../static/icons/contact.svg';
import personWorkingImage from './../../../static/icons/person-working.svg';
import handshakeImage from './../../../static/icons/handshake.svg';
import abstractBg from './../../../static/images/abstractbg2.jpg';

import strength1Image from './../../../static/images/strengths/1.png';
import strength2Image from './../../../static/images/strengths/2.JPG';
import strength3Image from './../../../static/images/strengths/3.JPG';

const styles = (theme) => ({
  root: {
    display: 'flex',
    overflow: 'hidden',
    backgroundImage: `url(${abstractBg})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
  },
  container: {
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(15),
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  contentContainer: {
    zIndex: 2,
    display: 'flex',
    flexDirection: 'column',
  },
  item: {
    display: 'flex',
    flexDirection: 'row',
  },
  title: {
    marginBottom: theme.spacing(4),
    color: 'white',
  },
  textHighlight: {
    color: theme.palette.accent.main,
  },
  number: {
    fontSize: 14,
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.secondary.main,
    fontWeight: theme.typography.fontWeightMedium,
    marginRight: 10,
  },
  headingText: {
    fontSize: 16,
    color: 'white',
  },
  text: {
    fontSize: 14,
    color: 'white',
  },
  image: {
    height: 55,
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  backgroundImage: {
    pointerEvents: 'none',
    position: 'absolute',
    width: '150%',
    top: -180,
    opacity: 0.5,
    zIndex: 1,
  },
  backgroundContainer: {
    position: 'absolute',
    zIndex: 1,
  },
  sliderImage: {
    maxHeight: 200,
  },
  button: {
    marginTop: theme.spacing(8),
  },
});

function ProductStrengths(props) {
  const { classes } = props;

  // Setup stacked carousel
  const ref = useRef(ResponsiveContainer);
  // Requires at least 3 images
  const data = [{cover: strength1Image, title: 'Interstaller'}, {cover: strength3Image, title: 'Interstaller'}, {cover: strength2Image, title: 'Interstaller'}];
  // Use memo to prevent rerendering everytime
  // memo will only re-render if theres a state change
  const Slide = React.memo(
    function (props: StackedCarouselSlideProps) {
        const { data, dataIndex } = props;
        const { cover } = data[dataIndex];
        return (
          <div style={{width: '100%', height: 300, userSelect: "none"}}>
            <img
                style={{height: '100%', width: '100%', objectFit: 'cover', borderRadius: 10}}
                draggable={false}
                src={cover}
            />
          </div>
        );
    }
  );

  return (
    <section className={classes.root}>
      <div className={classes.backgroundContainer}>
        {/* Documentation: https://particles.js.org/docs/interfaces/Options_Interfaces_Particles_IParticles.IParticles.html */}
        <Particles
          height="100vh"
          params={{
            "particles": {
              "number": {
                  "value": 100,
                  "density": {
                      "enable": false,
                      "value_area": 1500
                  }
              },
              "line_linked": {
                  "enable": true,
                  "opacity": 0.02
              },
              "move": {
                  "direction": "right",
                  "speed": 0.01
              },
              "size": {
                  "value": 2
              },
              "opacity": {
                  "anim": {
                      "enable": true,
                      "speed": 2,
                      "opacity_min": 0.05
                  }
              }
          },
        }} />
      </div>
      <Container className={classes.container}>
        <div className={classes.contentContainer}>
          <Fade
            triggerOnce
          >
            <Typography variant="h5" className={classes.title} align="left">
              Our <span className={classes.textHighlight}>Strengths</span>
            </Typography>
          </Fade>
          <div>
            <Grid container spacing={5}>
              <Grid item xs={12} md={4}>
                <Fade
                  triggerOnce
                  delay={200}
                >
                  <div className={classes.item}>
                    <div className={classes.number}>
                      <Chip label="1" color="secondary"/>
                    </div>
                    <div>
                      <Typography align="left" className={classes.headingText}>
                        <b>Dedicated Clearance Team</b>
                        <br/>
                        Our clearance never stops.
                      </Typography>
                      <Typography align="left" className={classes.text} style={{marginTop: 5}}>
                        ○ All clearance jobs from SATS/DNATA are handled directly by our own staff<br/>
                        ○ No outsourcing to 3rd party logistics company<br/>
                        ○ All incoming ship spares consignment are treated as urgent cargo
                      </Typography>
                    </div>
                  </div>
                </Fade>
              </Grid>

              <Grid item xs={12} md={4}>
                <Fade
                  triggerOnce
                  delay={400}
                >
                  <div className={classes.item}>
                    <div className={classes.number}>
                      <Chip label="2" color="secondary"/>
                    </div>
                    <div>
                      <Typography align="left" className={classes.headingText}>
                        <b>Digitalized Stock List / Vessel schedule</b>
                        <br/>
                        Integrating Technology
                      </Typography>
                      <Typography align="left" className={classes.text} style={{marginTop: 5}}>
                        ○ In-house <b>'LIVE'</b> stock system accessible 24/7<br/>
                        ○ Electronic Vessel schedule board<br/>
                        ○ Automatic clearance update<br/>
                      </Typography>
                    </div>
                  </div>
                </Fade>
              </Grid>

              <Grid item xs={12} md={4}>
                <Fade
                  triggerOnce
                  delay={600}
                >
                  <div className={classes.item}>
                    <div className={classes.number}>
                      <Chip label="3" color="secondary"/>
                    </div>
                    <div>
                      <Typography align="left" className={classes.headingText}>
                        <b>24/7 Capabilities</b>
                        <br/>
                        With you, always.
                      </Typography>
                      <Typography align="left" className={classes.text} style={{marginTop: 5}}>
                        ○ 3-Shift clearance (normal, after office hours and from 11PM onwards)<br/>
                        ○ Duty Drivers<br/>
                        ○ Key-appointment personnel available 24/7
                      </Typography>
                    </div>
                  </div>
                </Fade>
              </Grid>

            </Grid>
          </div>

        </div>
        <Grid container justifyContent="center" alignItems="center" style={{marginTop: 40}}>
          <Grid item xs={12} md={12}>
            <div style={{ width: '100%', position: 'relative' }}>
              <ResponsiveContainer carouselRef={ref} render={(parentWidth, carouselRef) => {
                let currentVisibleSlide = 5;
                if (parentWidth <= 1440) currentVisibleSlide = 3;
                else if (parentWidth <= 1080) currentVisibleSlide = 1;
                return (
                    <StackedCarousel
                      ref={carouselRef}
                      data={data}
                      carouselWidth={parentWidth}
                      slideWidth={750}
                      slideComponent={Slide}
                      maxVisibleSlide={5}
                      currentVisibleSlide={currentVisibleSlide}
                      useGrabCursor={true}
                    />
              )}}/>
            </div>
          </Grid>
        </Grid>
      </Container>
    </section>
  );
}

ProductStrengths.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ProductStrengths);
