import withRoot from './modules/withRoot';
// --- Post bootstrap -----
import React, { useEffect } from 'react';
import { useLocation } from "react-router-dom";
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Avatar from '@material-ui/core/Avatar';
import { useSpring, animated } from 'react-spring'
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Fade } from "react-awesome-reveal";

import Typography from './modules/components/Typography';
import AppAppBar from './modules/views/AppAppBar';
import AppFooter from './modules/views/AppFooter';

import deliveryImage from './../static/images/1.jpeg';
import deliveryImage2 from './../static/images/services/warehouse/1.JPG';

import documentsImage from './../static/images/s1.jpg';
import frozenFoodImage from './../static/images/s2.jpg';
import expediteClearanceImage from './../static/images/s3.jpg';

import importExportImage from './../static/images/services/importexport/1.jpeg';
import cruiseShipServicesImage from './../static/images/services/cruiseshipservices/1.JPG';
import doorToDoorImage from './../static/images/services/doortodoor/1.jpg';
import lastMileImage from './../static/images/services/lastmile/1.jpeg';
import projectCargoesImage from './../static/images/services/projectcargoes/1.jpeg';
import cruiseShipImage from './../static/images/services/cruiseshipservices/1.JPG';

import { ReactComponent as TruckSVG } from './../static/icons/truck.svg';

const useStyles = makeStyles((theme) => ({
  row: {
    display: 'flex',
    flexDirection:'row',
    alignItems:'center'
  },
  contentContainer: {
    marginBottom: theme.spacing(8),
  },
  image: {
    height: 50,
    marginLeft: 15,
    marginRight: 15,
  },
  serviceImage: {
    borderRadius: 10,
    display: 'block',
  },
  icon: {
    height: 35,
    margin: 5,
  },
  line: {
    borderLeft: '6px solid rgba(255,50,0,1)',
    marginRight: 10,
  },
  item: {
    display: 'flex',
    flexDirection: 'row',
  },
}));

function Services() {
  const location = useLocation();
  const classes = useStyles();
  const theme = useTheme();

  useEffect(()=> {
          if (location.hash) {
              let elem = document.getElementById(location.hash.slice(1))
              if (elem) {
                  elem.scrollIntoView({behavior: "smooth"})
              }
          } else {
          window.scrollTo({top:0,left:0, behavior: "smooth"})
          }
  }, [location,]);

  return (
    <React.Fragment>
      <AppAppBar />
      <Container style={{marginBottom: 80}}>
        <Box mt={6} mb={5}>
          <Typography variant="h3" marked="center" gutterBottom align="center">
            Our Services
          </Typography>
        </Box>

        {/* WAREHOUSING */}
        <div className={classes.contentContainer} id="sectionWarehousing">
          <Box className={classes.row} mb={4}>
            <div className={classes.item}>
              <div className={classes.line}/>
              <Typography variant="h4" align="left">
                Warehouse Distribution & Management
              </Typography>
            </div>
          </Box>

          <Grid container spacing={5} style={{marginBottom: 20}} alignItems="center">
            <Grid item xs={12} sm={6} md={7}>
              <Grid container item>
                <Typography variant="h6" marked="left" style={{marginBottom: 15}}>
                  Warehousing
                </Typography>
                <Typography>
                  We have  multiple warehouses across the East and West of Singapore including Changi, Pioneer Crescent and Tuas. <br/><br/>
                  The airport warehouse located within the Changi Aircargo Complex is a free trade zone area to provide timely support for the clearance of incoming air freight consignments. <br/><br/>
                  Half of our 22,000 square feet head office in Pioneer crescent is an approved Customs Zero-GST warehouse for storage of goods cleared via a Bonded Warehouse permit. This means that our clients will not have to make upfront GST payment.<br/><br/>
                  Apart from the aforementioned warehouses, there are 3 other units of warehouse located 	at the up and coming Tuas Megaport, SHINE@Tuas South Link. These are mainly used for the storage of offlanded items pending dispatch/re-forwarding instructions along with goods with already paid GST.
                </Typography>
              </Grid>
              <Grid container item style={{marginTop: 25}}>
                <Typography variant="h6" marked="left" style={{marginBottom: 15}}>
                  SECURITY & SAFETY
                </Typography>
                <Typography>
                  All our warehouses are furnished with CCTVs that have remote monitoring capabilities. This ensures round the clock safety for our clients’ cargoes.
                </Typography>
              </Grid>
              <Grid container item style={{marginTop: 25}}>
                <Typography variant="h6" marked="left" style={{marginBottom: 15}}>
                  VEHICLES FOR YOUR CARGO NEEDS
                </Typography>
                <Typography>
                  We are well equipped with a fleet of vehicles tailored to the various jobs that we undertake.<br/><br/>
                  • 10-footer to 24-footer trucks equipped with powered tailgate<br/>
                  • Open deck and canopy covered trucks<br/>
                  • Forklifts<br/>
                  • Powered hand pallets jacks<br/>
                  • Stackers to facilitate the handling of cargoes
                </Typography>
              </Grid>
            </Grid>
            <Grid container item xs={12} sm={6} md={5} justifyContent="center">
              <Fade
                triggerOnce
              >
                <img
                  src={deliveryImage}
                  alt="delivery"
                  width="100%"
                  className={classes.serviceImage}
                />
                <img
                  src={deliveryImage2}
                  alt="delivery"
                  width="100%"
                  className={classes.serviceImage}
                  style={{marginTop: 10}}
                />
              </Fade>
            </Grid>
          </Grid>
        </div>

        {/* IMPORT & EXPORT */}
        <div className={classes.contentContainer} id="sectionImportExport">
          <Box className={classes.row} mb={4}>
            <div className={classes.item}>
              <div className={classes.line}/>
              <Typography variant="h4" align="left">
                Import & Export
              </Typography>
            </div>
          </Box>

          <Grid container spacing={5} style={{marginBottom: 20}} alignItems="center">
            <Grid item xs={12} sm={6} md={7}>
              <Grid container item>
                <Typography variant="h6" marked="left" style={{marginBottom: 15}}>
                  Air Freight & Sea Freight
                </Typography>
                <Typography>
                  Our extensive network of local carriers, NVOCC operators and airlines, coupled with our great rapport with them enables us to provide our clients with attractive air and sea freight rates to any destination.
                </Typography>
              </Grid>
            </Grid>
            <Grid container item xs={12} sm={6} md={5} justifyContent="center">
              <Fade
                triggerOnce
              >
                <img
                  src={importExportImage}
                  alt="delivery"
                  width="100%"
                  height="300"
                  className={classes.serviceImage}
                />
              </Fade>
            </Grid>
          </Grid>
        </div>

        {/* CUSTOMS DOCUMENTATION & CLEARANCE */}
        <div className={classes.contentContainer} id="sectionCustoms">
          <Box className={classes.row} mb={4}>
            <div className={classes.item}>
              <div className={classes.line}/>
              <Typography variant="h4" align="left">
                Customs Documentation & Clearance
              </Typography>
            </div>
          </Box>

          <Grid container spacing={5} style={{marginBottom: 20}} alignItems="center">
            <Grid item xs={12} sm={6} md={7}>
              <Grid container item>
                <Typography variant="h6" marked="left" style={{marginBottom: 15}}>
                  Documentation & Clearance
                </Typography>
                <Typography>
                  All of our customs permits are cleared in-house in a timely fashion by our qualified permits officers to facilitate the prompt clearance of cargoes. We also have a separate team assigned to handle the air and sea freight clearance.<br/><br/>
                  A dedicated airport team is available 24/7 ​​to ensure the prompt and immediate clearance of urgent spares.
                </Typography>
              </Grid>
            </Grid>
            <Grid container item xs={12} sm={6} md={5} justifyContent="center">
              <Fade
                triggerOnce
              >
                <img
                  src={documentsImage}
                  alt="delivery"
                  width="100%"
                  className={classes.serviceImage}
                />
              </Fade>
            </Grid>
          </Grid>
        </div>

        {/* EXPEDITE CLEARANCE */}
        <div className={classes.contentContainer} id="sectionExpedite">
          <Box className={classes.row} mb={4}>
            <div className={classes.item}>
              <div className={classes.line}/>
              <Typography variant="h4" align="left">
                Expedite Clearance
              </Typography>
            </div>
          </Box>

          <Grid container spacing={5} style={{marginBottom: 20}} alignItems="center">
            <Grid item xs={12} sm={6} md={7}>
              <Grid container item>
                <Typography variant="h6" marked="left" style={{marginBottom: 15}}>
                  Urgent Clearance
                </Typography>
                <Typography>
                  To ensure that we are able to tend to any urgent arrival of shipments, we have drivers on standy round the clock.<br/><br/>
                  They will deliver shipments that require immediate clearance and delivery to loading points. Additionally, they are able to collect any urgent off landed spares that have to be sent to a workshop for urgent repair, along with returning these spares to the vessel prior to her departure from Singapore.
                </Typography>
              </Grid>
            </Grid>
            <Grid container item xs={12} sm={6} md={5} justifyContent="center">
              <Fade
                triggerOnce
              >
                <img
                  src={expediteClearanceImage}
                  alt="delivery"
                  width="100%"
                  className={classes.serviceImage}
                />
              </Fade>
            </Grid>
          </Grid>
        </div>

        {/* LAST-MILE DELIVERY */}
        <div className={classes.contentContainer} id="sectionLastMile">
          <Box className={classes.row} mb={4}>
            <div className={classes.item}>
              <div className={classes.line}/>
              <Typography variant="h4" align="left">
                Last-Mile Delivery
              </Typography>
            </div>
          </Box>

          <Grid container spacing={5} style={{marginBottom: 20}} alignItems="center">
            <Grid item xs={12} sm={6} md={7}>
              <Grid container item>
                <Typography variant="h6" marked="left" style={{marginBottom: 15}}>
                  DOOR TO DECK AND OPL SERVICES
                </Typography>
                <Typography>
                  As the most crucial part of the delivery process, we are able to provide Door-To-Deck service for urgent spares. We will collect the spares from our customers’ doors and deliver them directly onboard the vessel in the port or at the yard.<br/><br/>
                  We also provide OPL (Off-Port Limit) delivery jobs including communication and coordination with the ship’s Master to fix a specific time and place for the delivery job.
                </Typography>
              </Grid>
            </Grid>
            <Grid container item xs={12} sm={6} md={5} justifyContent="center">
              <Fade
                triggerOnce
              >
                <img
                  src={lastMileImage}
                  alt="delivery"
                  width="100%"
                  className={classes.serviceImage}
                />
              </Fade>
            </Grid>
          </Grid>
        </div>

        {/* PROJECT CARGOES */}
        <div className={classes.contentContainer} id="sectionProjectCargoes">
          <Box className={classes.row} mb={4}>
            <div className={classes.item}>
              <div className={classes.line}/>
              <Typography variant="h4" align="left">
                Project Cargoes
              </Typography>
            </div>
          </Box>

          <Grid container spacing={5} style={{marginBottom: 20}} alignItems="center">
            <Grid item xs={12} sm={6} md={7}>
              <Grid container item>
                <Typography variant="h6" marked="left" style={{marginBottom: 15}}>
                  Odd Sized Cargo
                </Typography>
                <Typography>
                  Our years of expertise have provided us with the capabilities to handle large, bulky and heavy weight shipments. These cargoes include cargo grabs and propellers.
                </Typography>
              </Grid>
            </Grid>
            <Grid container item xs={12} sm={6} md={5} justifyContent="center">
              <Fade
                triggerOnce
              >
                <img
                  src={projectCargoesImage}
                  alt="delivery"
                  width="100%"
                  className={classes.serviceImage}
                />
              </Fade>
            </Grid>
          </Grid>
        </div>

        {/* CRUISE SHIP */}
        <div className={classes.contentContainer} id="sectionTurnkeyProjects">
          <Box className={classes.row} mb={4}>
            <div className={classes.item}>
              <div className={classes.line}/>
              <Typography variant="h4" align="left">
                Turnkey Projects
              </Typography>
            </div>
          </Box>

          <Grid container spacing={5} style={{marginBottom: 20}} alignItems="center">
            <Grid item xs={12} sm={6} md={7}>
              <Grid container item direction="column">
                <Typography variant="h6" marked="left" style={{marginBottom: 15}}>
                  Turnkey Projects
                </Typography>
                <Typography>
                  As we aim to provide our clients with a holistic slew of services, we also offer the following:<br/>
                  • Cradles<br/>
                  • Stevedoring and mooring services (line mens)<br/>
                  • Crane<br/>
                  • Forklift Services<br/>
                  • Man-Lift (Cherry picker)<br/>
                  • Rental of reefer containers
                </Typography>
              </Grid>
            </Grid>
            <Grid container item xs={12} sm={6} md={5} justifyContent="center">
              <Fade
                triggerOnce
              >
                <img
                  src={cruiseShipImage}
                  alt="delivery"
                  width="100%"
                  className={classes.serviceImage}
                />
              </Fade>
            </Grid>
          </Grid>
        </div>

        {/* CLEARANCE OF FROZEN / CONTROLLED ITEMS */}
        <div className={classes.contentContainer} id="sectionFrozenControlled">
          <Box className={classes.row} mb={4}>
            <div className={classes.item}>
              <div className={classes.line}/>
              <Typography variant="h4" align="left">
                Clearance of Frozen / Controlled Items
              </Typography>
            </div>
          </Box>

          <Grid container spacing={5} style={{marginBottom: 20}} alignItems="center">
            <Grid item xs={12} sm={6} md={7}>
              <Grid container item>
                <Typography variant="h6" marked="left" style={{marginBottom: 15}}>
                  Clearance
                </Typography>
                <Typography>
                  We have the necessary proficiency and vehicles available for the clearance of perishable cargoes. These items include caviars, fresh-cut flowers and frozen meats that will be delivered to cruise vessels berthed at the Singapore Cruise Centre of Marina Bay Cruise Centre.
                </Typography>
              </Grid>
            </Grid>
            <Grid container item xs={12} sm={6} md={5} justifyContent="center">
              <Fade
                triggerOnce
              >
                <img
                  src={frozenFoodImage}
                  alt="delivery"
                  width="100%"
                  className={classes.serviceImage}
                />
              </Fade>
            </Grid>
          </Grid>
        </div>


      </Container>
      <AppFooter />
    </React.Fragment>
  );
}

export default withRoot(Services);
