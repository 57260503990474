import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { useSpring, animated } from 'react-spring'
import Button from '../components/Button';
import Typography from '../components/Typography';
import ProductHeroLayout from './ProductHeroLayout';
import backgroundImage from './../../../static/images/1.jpeg';

import logoImage from './../../../static/images/logo.png';

const styles = (theme) => ({
  background: {
    backgroundImage: `url(${backgroundImage})`,
    backgroundColor: '#7fc7d9', // Average color of the background image.
    backgroundPosition: 'center',
    backgroundAttachment: 'fixed',
  },
  button: {
    minWidth: 200,
  },
  lightHeaderText: {
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(4),
    fontSize: 20,
    fontWeight: theme.typography.fontWeightLight,
  },
  more: {
    marginTop: theme.spacing(2),
  },
  textSecondaryMain: {
    color: theme.palette.secondary.main
  },
  textAccentMain: {
    color: theme.palette.accent.main
  },
  logo: {
    width: 200,
  },
  item: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: theme.spacing(8),
  },
});

function ProductHero(props) {
  const { classes } = props;

  const springFadeProps = useSpring({
    delay: 500,
    from: { opacity: 0 },
    to: { opacity: 1 },
  });

  return (
    <ProductHeroLayout backgroundClassName={classes.background}>
      {/* Increase the network loading priority of the background image. */}
      <img style={{ display: 'none' }} src={backgroundImage} alt="increase priority" />
      <img
        src={logoImage}
        alt="refresh"
        className={classes.logo}
        style={{marginTop: 74}}
      />
      <Typography color="inherit" align="center" variant="h5" style={{marginTop: 10, marginBottom: 40, textTransform: 'capitalize'}}>
        Commonwealth Freight Services Pte Ltd.
      </Typography>

      <Typography color="inherit" align="left" variant="h3" marked="left" style={{width: '80%'}}>
        Your <animated.span style={springFadeProps} className={classes.textAccentMain}>One Stop</animated.span> Solution<br/>
        <Typography color="inherit" align="left" variant="h1">
          For Goods Delivery
        </Typography>
      </Typography>
      <Typography color="inherit" align="center" className={classes.lightHeaderText}>
          Competitive. Convenient. Customer-Centric.
      </Typography>
      <Button
        color="secondary"
        variant="contained"
        size="large"
        className={classes.button}
        component="a"
        href="/services"
      >
        Our Services
      </Button>
    </ProductHeroLayout>
  );
}

ProductHero.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ProductHero);
