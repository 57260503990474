import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ButtonBase from '@material-ui/core/ButtonBase';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import { Fade } from "react-awesome-reveal";
import Button from '../components/Button';
import Typography from '../components/Typography';

import deliveryImage from './../../../static/images/1.jpeg';
import documentsImage from './../../../static/images/s1.jpg';
import frozenFoodImage from './../../../static/images/s2.jpg';
import expediteClearanceImage from './../../../static/images/s3.jpg';
import portImage from './../../../static/images/s4.jpg';
import abstractBg from './../../../static/images/abstractbg.jpg';

import importExportImage from './../../../static/images/services/importexport/1.jpeg';
import projectCargoesImage from './../../../static/images/services/projectcargoes/1.jpeg';
import cruiseShipImage from './../../../static/images/services/cruiseshipservices/1.JPG';

import warehouseIcon from './../../../static/icons/warehouse.png';
import securityIcon from './../../../static/icons/security-camera.png';
import semiIcon from './../../../static/icons/semi.png';

const styles = (theme) => ({
  root: {
    display: 'flex',
    overflow: 'hidden',
    backgroundImage: `url(${abstractBg})`,
    backgroundPosition: 'center',
    backgroundAttachment: 'fixed',
    backgroundSize: 'cover',
  },
  container: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(4),
  },
  icon: {
    height: 35,
    margin: 5,
  },
  highlightContainer: {
    backgroundColor: 'rgba(255,255,255,0.4)',
    borderBottom: '2px solid rgba(0,0,0,0.1)',
    padding: 20,
    borderRadius: 10,
    marginTop: 30,
    marginBottom: 20,
  },
  textHighlight: {
    color: theme.palette.secondary.main,
  },
  images: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  imageWrapper: {
    position: 'relative',
    display: 'block',
    padding: 0,
    borderRadius: 0,
    height: '40vh',
    [theme.breakpoints.down('sm')]: {
      width: '100% !important',
      height: 100,
    },
    '&:hover': {
      zIndex: 1,
    },
    '&:hover $imageBackdrop': {
      opacity: 0.15,
    },
    '&:hover $imageMarked': {
      opacity: 0,
    },
    '&:hover $imageTitle': {
      border: '4px solid currentColor',
    },
  },
  imageButton: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.common.white,
  },
  imageSrc: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: 'cover',
    backgroundPosition: 'center 40%',
  },
  imageBackdrop: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    background: theme.palette.common.black,
    opacity: 0.5,
    transition: theme.transitions.create('opacity'),
  },
  imageTitle: {
    position: 'relative',
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px 14px`,
  },
  imageMarked: {
    height: 3,
    width: 18,
    background: theme.palette.common.white,
    position: 'absolute',
    bottom: -2,
    left: 'calc(50% - 9px)',
    transition: theme.transitions.create('opacity'),
  },
});

function ProductCategories(props) {
  const { classes } = props;

  const images = [
    {
      url: importExportImage,
      title: 'Import And Export',
      width: '55%',
      section: '#sectionImportExport',
    },
    {
      url: deliveryImage,
      title: 'Warehousing',
      width: '45%',
      section: '#sectionWarehousing',
    },
    {
      url: documentsImage,
      title: 'Customs Documentation & Clearance',
      width: '38%',
      section: '#sectionCustoms',
    },
    {
      url: expediteClearanceImage,
      title: 'Expedite Clearance',
      width: '24%',
      section: '#sectionExpedite',
    },
    {
      url: portImage,
      title: 'Last-Mile Delivery',
      width: '38%',
      section: '#sectionLastMile',
    },
    {
      url: projectCargoesImage,
      title: 'Project Cargoes',
      width: '35%',
      section: '#sectionProjectCargoes',
    },
    {
      url: cruiseShipImage,
      title: 'Turnkey Projects',
      width: '30%',
      section: '#sectionTurnkeyProjects',
    },
    {
      url: frozenFoodImage,
      title: 'Frozen / Controlled Items Clearance',
      width: '35%',
      section: '#sectionFrozenControlled',
    },
  ];

  return (
    <div className={classes.root}>
      <Container className={classes.container} component="section">
        <Fade
          triggerOnce
        >
          <Typography variant="h4" align="center" component="h2">
            <span className={classes.textHighlight}>Discover</span> Our Services
          </Typography>
        </Fade>

        <Grid container className={classes.highlightContainer} style={{marginBottom: 40}}>
          <Grid item xs={12} sm={4} md={4} align="center">
            <Fade
              triggerOnce
              delay={200}
            >
              <img
                src={warehouseIcon}
                alt="company"
                className={classes.icon}
              />
              <Typography variant="h6" marked="center" align="center" style={{marginBottom: 15}}>
                Massive Warehouses
              </Typography>
              <Typography align="center">
                With <b>Customs Zero-GST, Free-Trade Zone</b> and <b>Local</b> warehouses spanning from 4,500 sqft to 21,000 sqft, we are strategically located right next to major loading points. Rest assured that we are able to accommodate all types and sizes of cargo.
              </Typography>
            </Fade>
          </Grid>
          <Grid item xs={12} sm={4} md={4} align="center" style={{paddingLeft: 10, paddingRight: 10}}>
            <Fade
              triggerOnce
              delay={400}
            >
              <img
                src={securityIcon}
                alt="company"
                className={classes.icon}
              />
              <Typography variant="h6" marked="center" align="center" style={{marginBottom: 15}}>
                Security & Safety
              </Typography>
              <Typography align="center">
                CCTVs with remote monitoring capabilities are installed in every facility to <br/>ensure your cargo is safe <b>24/7</b>.
              </Typography>
            </Fade>
          </Grid>
          <Grid item xs={12} sm={4} md={4} align="center">
            <Fade
              triggerOnce
              delay={600}
            >
              <img
                src={semiIcon}
                alt="company"
                className={classes.icon}
              />
              <Typography variant="h6" marked="center" align="center" style={{marginBottom: 15}}>
                Equipment For Any Cargo
              </Typography>
              <Typography align="center">
                We are equipped with vehicles ranging from<br/><b>Open-Deck Trucks <small>(10' to 24'/10-tonne/Power Tailgate)</small></b> to <b>Forklifts <small>(7-Ton and 3-Ton c/w attachment for Handling Luboils/Chemicals in metallic drums)</small></b> to accommodate cargo of any size and type.

              </Typography>
            </Fade>
          </Grid>
        </Grid>

        <div className={classes.images}>
          {images.map((image) => (
            <ButtonBase
              key={image.title}
              className={classes.imageWrapper}
              style={{
                width: image.width,
              }}
            >
              <Fade
                triggerOnce
              >
                <Link
                  href={"/services/" + image.section}
                >
                  <div
                    className={classes.imageSrc}
                    style={{
                      backgroundImage: `url(${image.url})`,
                    }}
                  />
                  <div className={classes.imageBackdrop} />
                  <div className={classes.imageButton}>
                    <Typography
                      component="h3"
                      variant="h6"
                      color="inherit"
                      className={classes.imageTitle}
                    >
                      {image.title}
                      <div className={classes.imageMarked} />
                    </Typography>
                  </div>
                </Link>
              </Fade>
            </ButtonBase>
          ))}
        </div>
        <Box textAlign='center' mt={5}>
          <Button
            color="secondary"
            size="large"
            variant="outlined"
            component="a"
            href="/about"
          >
            View All Services
          </Button>
        </Box>
      </Container>
    </div>
  );
}

ProductCategories.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ProductCategories);
