import withRoot from './modules/withRoot';
// --- Post bootstrap -----
import React from 'react';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { useSpring, animated } from 'react-spring';
import { Field, Form, FormSpy } from 'react-final-form';
import { useMediaQuery } from "@material-ui/core";
import emailjs from 'emailjs-com';

import { email, required } from './modules/form/validation';
import AppForm from './modules/views/AppForm';
import RFTextField from './modules/form/RFTextField';
import FormButton from './modules/form/FormButton';
import FormFeedback from './modules/form/FormFeedback';

import Typography from './modules/components/Typography';
import Link from '@material-ui/core/Link';
import { Fade } from "react-awesome-reveal";
import AppAppBar from './modules/views/AppAppBar';
import AppFooter from './modules/views/AppFooter';

import contactLocationImage from './../static/images/contact_location.png';

const useStyles = makeStyles((theme) => ({
  form: {
    marginTop: theme.spacing(2),
  },
  button: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  feedback: {
    marginTop: theme.spacing(2),
  },
}));

function Contact() {
  const classes = useStyles();

  const defaultProps = {
    center: {
      lat: 1.3150183549201728,
      lng: 103.69462820472795
    },
    zoom: 11
  };

  const [sent, setSent] = React.useState(false);

  const validate = (values) => {
    const errors = required(['firstName', 'lastName', 'email', 'enquiry'], values);

    if (!errors.email) {
      const emailError = email(values.email, values);
      if (emailError) {
        errors.email = email(values.email, values);
      }
    }

    return errors;
  };

  // Send email
  const USER_ID = "user_T85cJmAum5oLjRIKawRUV";
  const SERVICE_ID = "service_5a99edm";
  const TEMPLATE_ID = "template_zspzcxa";

  const onSubmit = async (values) => {
    setSent(true);
    let templateParams = {
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      enquiry: values.enquiry,
    }

    emailjs.send(SERVICE_ID, TEMPLATE_ID, templateParams, USER_ID)
      .then((result) => {
      }, (error) => {
      });
  };

  const isSm = useMediaQuery(theme => theme.breakpoints.up("sm")) ? false : true;

  return (
    <React.Fragment>
      <AppAppBar />
      <Container style={{paddingBottom: 0}}>
        <Fade
          triggerOnce
        >
          <Box mt={7} mb={12}>
            <Typography variant="h3" gutterBottom marked="center" align="center">
              Contact Us
            </Typography>
          </Box>
        </Fade>
        <Grid container spacing={(isSm) ? 0 : 10} justify="center">
          <Grid item style={{marginBottom: (isSm) ? 20 : 0}}>
            <Fade
              triggerOnce
              delay={200}
            >
              <Typography variant="h6" marked="center" align="center" style={{marginBottom: 15}}>
                Locate
              </Typography>
              <Typography align="center" style={{marginBottom: 20}}>
                28 Pioneer Crescent, #04-13
                <br/>
                Westpark Bizcentral, Singapore 628559
              </Typography>
              <Link
                color="inherit"
                href="https://goo.gl/maps/Bc6E7Nb2LNAJZvnn8"
                target="_blank"
              >
                <img
                  src={contactLocationImage}
                  height="256"
                />
              </Link>
            </Fade>
          </Grid>
          <Grid item xs={12} sm={'auto'}>
            <Fade
              triggerOnce
              delay={400}
            >
              <Typography variant="h6" marked="center" align="center" style={{marginBottom: 15}}>
                Connect
              </Typography>
              <Typography align="center" style={{marginBottom: 10}}>
                <b>Telephone</b><br/>
                +65 6776 6077
              </Typography>
              <Typography align="center" style={{marginBottom: 10}}>
                <b>Fax</b><br/>
                +65 6779 6731
              </Typography>

              <Typography align="center">
                <b>Email</b><br/>
                <Link
                  color="inherit"
                  href="mailto:operations@cwealth.com.sg"
                >
                  {'operations@cwealth.com.sg'}
                </Link>
              </Typography>
            </Fade>
          </Grid>
        </Grid>

        <Fade
          triggerOnce
          delay={400}
        >
          <Grid container spacing={5}>
            <Grid item xs={12} sm={12} md={12}>
              <AppForm>
              { (sent)
                ? (
                <Typography variant="h4" align="center">
                  Thank you!
                  <Typography align="center">
                  We have received your enquiry and will respond to you soon.
                  </Typography>
                </Typography>
                )
                :
              (
                <div>
                  <Typography variant="h6" gutterBottom marked="center" align="center" style={{marginBottom: 30}}>
                    Alternatively,
                    <Typography align="center">
                    You may send your enquiries using the form below
                    </Typography>
                  </Typography>
                  <Form onSubmit={onSubmit} subscription={{ submitting: true }} validate={validate}>
                    {props => (
                      <form onSubmit={props.handleSubmit} className={classes.form} noValidate>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={6}>
                            <Field
                              component={RFTextField}
                              autoComplete="fname"
                              fullWidth
                              label="First name"
                              name="firstName"
                              required
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Field
                              component={RFTextField}
                              autoComplete="lname"
                              fullWidth
                              label="Last name"
                              name="lastName"
                              required
                            />
                          </Grid>
                        </Grid>
                        <Field
                          autoComplete="email"
                          component={RFTextField}
                          disabled={props.submitting || sent}
                          fullWidth
                          label="Email"
                          margin="normal"
                          name="email"
                          required
                        />
                        <Field
                          fullWidth
                          component={RFTextField}
                          disabled={props.submitting || sent}
                          required
                          multiline={true}
                          name="enquiry"
                          label="Enquiry"
                        />
                        <FormSpy subscription={{ submitError: true }}>
                          {({ submitError }) =>
                            submitError ? (
                              <FormFeedback className={classes.feedback} error>
                                {submitError}
                              </FormFeedback>
                            ) : null
                          }
                        </FormSpy>
                        <FormButton
                          className={classes.button}
                          disabled={props.submitting || sent}
                          color="secondary"
                          fullWidth
                        >
                          {'Submit'}
                        </FormButton>
                      </form>
                    )}
                  </Form>
                </div>
              )}
              </AppForm>
            </Grid>
          </Grid>
        </Fade>
      </Container>
      <AppFooter />
    </React.Fragment>
  );
}

export default withRoot(Contact);
