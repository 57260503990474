import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';
import FlipNumbers from 'react-flip-numbers';
import { useMediaQuery } from "@material-ui/core";
import { withStyles, useTheme } from '@material-ui/core/styles';
import Typography from '../components/Typography';

import contactImage from './../../../static/icons/contact.svg';


const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: theme.spacing(9),
    marginBottom: theme.spacing(9),
  },
  button: {
    border: '4px solid currentColor',
    borderRadius: 0,
    height: 'auto',
    padding: theme.spacing(2, 5),
  },
  link: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  image: {
    width: 40,
    alignSelf: 'center',
  },
});

function ProductSmokingHero(props) {
  const { classes } = props;
  const theme = useTheme();
  console.log(Math.floor(Date.now() / 1000));
  const startingTotal = Math.floor((Math.floor(Date.now() / 1000) - 1642650818) * 0.0217);
  const [total, setTotal] = React.useState(startingTotal);

  React.useEffect(() => {
    setInterval(() => {
        setTotal(total => total + 1);
    }, 2200);
  }, []);

  const isSm = useMediaQuery(theme => theme.breakpoints.up("sm")) ? false : true;

  return (
    <Container className={classes.root} component="section">
      <Typography variant="subtitle1" style={{marginBottom: 10}}>
        Total orders fulfilled to date
      </Typography>
      <div style={{marginBottom: 100}}>
        <FlipNumbers
          height={(isSm) ? 40 : 65}
          width={(isSm) ? 40 : 65}
          color={theme.palette.secondary.dark}
          play
          numbers={`${total}`}
        />
      </div>

      <Link
        underline="none"
        href="/contact"
      >
        <Button className={classes.button}>
          <Typography variant="h5" component="span">
            <div style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
            }}>
                CONTACT US
                <img
                  src={contactImage}
                  alt="contact"
                  className={classes.image}
                  style={{marginLeft: 10}}
                />
            </div>
          </Typography>
        </Button>
      </Link>
      <Typography variant="subtitle1" className={classes.link}>
        Ready to start? <b>Get in touch!</b>
      </Typography>

    </Container>
  );
}

ProductSmokingHero.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ProductSmokingHero);
