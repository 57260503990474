import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';
import Chip from '@material-ui/core/Chip';
import { Fade } from "react-awesome-reveal";
import Button from '../components/Button';
import Typography from '../components/Typography';

import contactImage from './../../../static/icons/contact.svg';
import personWorkingImage from './../../../static/icons/person-working.svg';
import handshakeImage from './../../../static/icons/handshake.svg';
import abstractBg from './../../../static/images/abstractbg3.jpg';

const styles = (theme) => ({
  root: {
    display: 'flex',
    overflow: 'hidden',
    backgroundImage: `url(${abstractBg})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
  },
  container: {
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(15),
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  contentContainer: {
    zIndex: 2,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  item: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(0, 5),
  },
  title: {
    marginBottom: theme.spacing(14),
  },
  textHighlight: {
    color: theme.palette.secondary.main,
  },
  number: {
    fontSize: 24,
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.secondary.main,
    fontWeight: theme.typography.fontWeightMedium,
  },
  text: {
    fontSize: 20
  },
  image: {
    height: 55,
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  backgroundImage: {
    pointerEvents: 'none',
    position: 'absolute',
    width: '150%',
    top: -180,
    opacity: 0.5,
    zIndex: 1,
  },
  button: {
    marginTop: theme.spacing(8),
  },
});

function ProductHowWeWork(props) {
  const { classes } = props;

  return (
    <section className={classes.root}>
      <Container className={classes.container}>
        <div className={classes.contentContainer}>
          <Fade
            triggerOnce
          >
            <Typography variant="h4" className={classes.title} component="h2">
              How We <span className={classes.textHighlight}>Work</span>
            </Typography>
          </Fade>
          <div>
            <Grid container spacing={5}>
              <Grid item xs={12} md={4}>
                <Fade
                  triggerOnce
                  delay={200}
                >
                  <div className={classes.item}>
                    <div className={classes.number}>
                      <Chip label="1" color="secondary"/>
                    </div>
                    <img
                      src={contactImage}
                      alt="contact"
                      className={classes.image}
                    />
                    <Typography align="center" className={classes.text}>
                      <Link
                        color="inherit"
                        href="/contact"
                      >
                        <b><u>Connect with us.</u></b>
                      </Link>
                      <br/>
                      Get a quotation for a particular service.
                    </Typography>
                  </div>
                </Fade>
              </Grid>
              <Grid item xs={12} md={4}>
                <Fade
                  triggerOnce
                  delay={400}
                >
                  <div className={classes.item}>
                    <div className={classes.number}>
                      <Chip label="2" color="secondary"/>
                    </div>
                    <img
                      src={personWorkingImage}
                      alt="Working"
                      className={classes.image}
                    />
                    <Typography align="center" className={classes.text}>
                      <b>Let us do the work!</b><br/>
                      We will work with you to understand your needs better.
                    </Typography>
                  </div>
                </Fade>
              </Grid>
              <Grid item xs={12} md={4}>
                <Fade
                  triggerOnce
                  delay={600}
                >
                  <div className={classes.item}>
                    <div className={classes.number}>
                      <Chip label="3" color="secondary"/>
                    </div>
                    <img
                      src={handshakeImage}
                      alt="handshake"
                      className={classes.image}
                    />
                    <Typography align="center" className={classes.text}>
                      <b>Keep in touch.</b><br/>
                      Our service managers and automated systems will keep you in the loop.
                    </Typography>
                  </div>
                </Fade>
              </Grid>
            </Grid>
          </div>
          <Fade
            triggerOnce
            delay={800}
          >
            <Button
              color="secondary"
              size="large"
              variant="contained"
              className={classes.button}
              component="a"
              href="/about"
            >
              About Us
            </Button>
          </Fade>
        </div>
      </Container>
    </section>
  );
}

ProductHowWeWork.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ProductHowWeWork);
