import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';
import Chip from '@material-ui/core/Chip';
import { Fade } from "react-awesome-reveal";
import Button from '../components/Button';
import Typography from '../components/Typography';

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import deliveryImage from './../../../static/images/1.jpeg';

import pc1Image from './../../../static/images/projectcargoes/1.JPG';
import pc2Image from './../../../static/images/projectcargoes/2.JPG';
import pc3Image from './../../../static/images/projectcargoes/3.JPG';
import pc4Image from './../../../static/images/projectcargoes/4.JPG';
import pc5Image from './../../../static/images/projectcargoes/5.JPG';
import pc6Image from './../../../static/images/projectcargoes/6.JPG';

import contactImage from './../../../static/icons/contact.svg';
import personWorkingImage from './../../../static/icons/person-working.svg';
import handshakeImage from './../../../static/icons/handshake.svg';
import abstractBg from './../../../static/images/abstractbg.jpg';

const styles = (theme) => ({
  root: {
    display: 'flex',
    overflow: 'hidden',
    backgroundImage: `url(${abstractBg})`,
    backgroundPosition: 'center',
    backgroundAttachment: 'fixed',
    backgroundSize: 'cover',
  },
  container: {
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(15),
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  contentContainer: {
    zIndex: 2,
    display: 'flex',
    flexDirection: 'column',
  },
  item: {
    display: 'flex',
    flexDirection: 'row',
  },
  title: {
    marginBottom: theme.spacing(2),
  },
  subTitle: {
    marginBottom: theme.spacing(4),
    fontSize: 18,
    fontWeight: theme.typography.fontWeightLight,
  },
  number: {
    fontSize: 14,
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.secondary.main,
    fontWeight: theme.typography.fontWeightMedium,
    marginRight: 10,
  },
  line: {
    borderLeft: '2px solid rgba(255,50,0,1)',
    marginRight: 10,
  },
  lineBetween: {
    borderLeft: '2px solid rgba(0,0,0,0.2)',
    height: 40,
    marginRight: 10,
  },
  headingText: {
    fontSize: 16
  },
  text: {
    fontSize: 14
  },
  image: {
    height: 55,
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  sliderImage: {
    maxHeight: 200,
  },
  backgroundImage: {
    pointerEvents: 'none',
    position: 'absolute',
    width: '150%',
    top: -180,
    opacity: 0.5,
    zIndex: 1,
  },
  button: {
    marginTop: theme.spacing(8),
  },
});

function ProductExpertise(props) {
  const { classes } = props;

  var sliderSettings = {
    dots: true,
    dotsClass:"vertical-dots",
    autoplay: true,
    autoplaySpeed: 4000,
    speed: 500,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    vertical: true,
    verticalSwiping: true,
    arrows: false,
  };

  return (
    <section className={classes.root}>
      <Container className={classes.container}>
        <div className={classes.contentContainer}>
          <div>
            <Grid container spacing={0}>
              <Grid item xs={12} md={8}>
                <Grid container item spacing={0}>
                  <Grid item xs={12} md={12}>
                    <Fade
                      triggerOnce
                    >
                      <Typography variant="h5" marked="left" className={classes.title} align="left">
                        Expertise
                      </Typography>
                      <Typography className={classes.subTitle} align="left">
                        With over <b>20 years of experience</b>, we are committed to your needs.<br/>
                      </Typography>
                    </Fade>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <div className={classes.item}>
                      <div className={classes.line}/>
                      <div>
                        <Fade
                          triggerOnce
                          delay={200}
                        >
                          <Typography align="left" className={classes.headingText}>
                            <b>Focused Business</b>
                          </Typography>
                          <Typography align="left" className={classes.text} style={{marginTop: 5}}>
                            • 98% of our customers are either ship owners, agents, chandlers or ship spare parts supplier<br/>
                            • Broad-based vessel knowledge<br/>
                            • Vessels under our care include Luxury Yachts / Pax. Cruise, bulk carrier, tankers, LPGs, LNGs, research vessels.
                          </Typography>
                        </Fade>
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <div className={classes.lineBetween}/>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <div className={classes.item}>
                      <div className={classes.line}/>
                      <div>
                        <Fade
                          triggerOnce
                          delay={400}
                        >
                          <Typography align="left" className={classes.headingText}>
                            <b>Controlled items clearance</b>
                          </Typography>
                          <Typography align="left" className={classes.text} style={{marginTop: 5}}>
                            • Bonded store (e.g. wine, champagne)<br/>
                            • Jackpot machine (controlled by SPF)<br/>
                            • Caviar (extinct / endangered species - controlled by AVA)<br/>
                          </Typography>
                        </Fade>
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <div className={classes.lineBetween}/>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <div className={classes.item}>
                      <div className={classes.line}/>
                      <div>
                        <Fade
                          triggerOnce
                          delay={600}
                        >
                          <Typography align="left" className={classes.headingText}>
                            <b>Project Cargoes</b>
                          </Typography>
                          <Typography align="left" className={classes.text} style={{marginTop: 5}}>
                            • Over-side discharging of O.O.G via tug and barge (e.g. Scrubbers)<br/>
                            • Discharge into sea (Lifeboats)<br/>
                            • FPSO projects cargoes <br/>
                            • PSA container switching
                          </Typography>
                        </Fade>
                      </div>
                    </div>
                  </Grid>

                </Grid>
              </Grid>
              <Grid item xs={12} md={4} style={{marginTop: 40}}>
                <Grid container item spacing={0} align="center">
                  <Grid item xs={12} md={12}>
                    <Slider {...sliderSettings}>
                      <div>
                        <img className={classes.sliderImage} src={pc1Image} />
                      </div>
                      <div>
                        <img className={classes.sliderImage} src={pc2Image} />
                      </div>
                      <div>
                        <img className={classes.sliderImage} src={pc3Image} />
                      </div>
                      <div>
                        <img className={classes.sliderImage} src={pc4Image} />
                      </div>
                      <div>
                        <img className={classes.sliderImage} src={pc5Image} />
                      </div>
                      <div>
                        <img className={classes.sliderImage} src={pc6Image} />
                      </div>
                    </Slider>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </div>
      </Container>
    </section>
  );
}

ProductExpertise.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ProductExpertise);
