import withRoot from './modules/withRoot';
// --- Post bootstrap -----
import React from 'react';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Avatar from '@material-ui/core/Avatar';
import { makeStyles } from '@material-ui/core/styles';
import { useSpring, animated } from 'react-spring';
import { Fade } from "react-awesome-reveal";

import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import TimelineDot from '@material-ui/lab/TimelineDot';

import Typography from './modules/components/Typography';
import AppAppBar from './modules/views/AppAppBar';
import AppFooter from './modules/views/AppFooter';
import TeamSection from './modules/views/TeamSection';

import companyIcon from './../static/icons/company.svg';
import refreshIcon from './../static/icons/refresh-page-option.png';
import relocateIcon from './../static/icons/relocation.png';
import cheerImage from './../static/images/cheer.jpg';

const useStyles = makeStyles((theme) => ({
  image: {
    height: 20,
    padding: 1,
  },
}));

function About() {
  const classes = useStyles();

  const springFadePropsDelay200 = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    delay: 200,
  });

  const springFadePropsDelay400 = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    delay: 400,
  });

  return (
    <React.Fragment>
      <AppAppBar />
      <Container style={{marginBottom: 80}}>
        <Fade
          triggerOnce
        >
          <Box mt={7} mb={12}>
            <Typography variant="h3" gutterBottom marked="center" align="center">
              Who we are
            </Typography>
          </Box>
        </Fade>

        <Grid container spacing={0}>
          <Grid item xs={12} sm={6} md={6}>
            <Fade
              triggerOnce
              delay={200}
            >
              <Typography variant="h6" marked="left" style={{marginBottom: 15}}>
                The Company
              </Typography>
              <Typography>
                Since our inception in 2001, Commonwealth Freight has established itself as being one of the leading freight forwarding companies in Singapore. Our many years of valuable expertise have enabled us to confidently provide our clients with specially tailored solutions, unique to each individual shipment.
                <br/><br/>
                Our array of specializations include the handling of various marine ship spares in-transit shipments arriving via air or sea freight, along with the consolidation of any other spares coming from local suppliers or ship chandlers.
                <br/><br/>
                Majority of our clients include ship owners, shipping managers, shipping agencies, marine spares suppliers, spares purchasing agents as well as local ship chandlers.
                <br/><br/>
                The fleet of vessels that we handle comprise bulkcarriers, container vessels, international cruise vessels, rigs, oil tankers, LPG/LNG vessels, cable laying vessels, along with scientific research ships.
              </Typography>
            </Fade>
          </Grid>
          {/* TIMELINE */}
          <Grid item xs={12} sm={6} md={6}>
            <Fade
              triggerOnce
              delay={400}
            >
              <Timeline>
                <TimelineItem>
                  <TimelineOppositeContent>
                    <Typography variant="body2" color="textSecondary">
                      1986
                    </Typography>
                  </TimelineOppositeContent>
                  <TimelineSeparator>
                    <TimelineDot color="primary" variant="outlined">
                      <img
                        src={companyIcon}
                        alt="company"
                        className={classes.image}
                      />
                    </TimelineDot>
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent>
                    <Typography variant="h6">
                      Founding
                    </Typography>
                    <Typography>
                      <b>Commercial Freight & Storage Services Pte Ltd</b> was founded
                    </Typography>
                  </TimelineContent>
                </TimelineItem>

                <TimelineItem>
                  <TimelineOppositeContent>
                    <Typography variant="body2" color="textSecondary">
                      2001
                    </Typography>
                  </TimelineOppositeContent>
                  <TimelineSeparator>
                    <TimelineDot color="primary" variant="outlined">
                      <img
                        src={refreshIcon}
                        alt="refresh"
                        className={classes.image}
                      />
                    </TimelineDot>
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent>
                    <Typography variant="h6">
                      Rebranding
                    </Typography>
                    <Typography>
                      To better establish the nature of our business and the uptake of freight, logistics and warehousing services, we rebranded ourselves as <b>Commonwealth Freight Services Pte Ltd.</b>
                    </Typography>
                  </TimelineContent>
                </TimelineItem>

                <TimelineItem>
                  <TimelineOppositeContent>
                    <Typography variant="body2" color="textSecondary">
                      2015
                    </Typography>
                  </TimelineOppositeContent>
                  <TimelineSeparator>
                    <TimelineDot color="primary" variant="outlined">
                      <img
                        src={relocateIcon}
                        alt="relocate"
                        className={classes.image}
                      />
                    </TimelineDot>
                  </TimelineSeparator>
                  <TimelineContent>
                    <Typography variant="h6">
                      Relocation
                    </Typography>
                    <Typography>
                      Moved to a larger and newer facility in Westpark Bizcentral to accommodate bulkier and heavier items.
                    </Typography>
                  </TimelineContent>
                </TimelineItem>
              </Timeline>
            </Fade>
          </Grid>

          <TeamSection />

        </Grid>
      </Container>
      <AppFooter />
    </React.Fragment>
  );
}

export default withRoot(About);
