import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import AppBar from '../components/AppBar';
import Toolbar, { styles as toolbarStyles } from '../components/Toolbar';
import AppBarCollapse from "../components/AppBarCollapse";

import logoImage from './../../../static/images/logo.png';

const styles = (theme) => ({
  title: {
    fontSize: 24,
  },
  placeholder: toolbarStyles(theme).root,
  toolbar: {
    justifyContent: 'space-between',
  },
  background: {
    background: '#470500',
  },
  backgroundTransparent: {
    backgroundColor: 'rgba(0,0,0,0)'
  },
  leftLinkActive: {
    color: theme.palette.common.white,
  },
  right: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  rightLink: {
    fontSize: 16,
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
    },
  },
  linkSecondary: {
    color: theme.palette.accent.main,
  },
  logo: {
    height: 65,
    marginTop: 5,
  },
});

function AppAppBar(props) {
  const { classes, transparent, position, hideHome } = props;

  return (
    <div>
      <AppBar className={(transparent) ? classes.backgroundTransparent : classes.background} position={(position) ? position : 'static'}>
        <Toolbar className={classes.toolbar}>
          {
            (!hideHome) &&
            (
              <div className={classes.left}>
                <Link
                  variant="h6"
                  underline="none"
                  color="inherit"
                  className={classes.title}
                  href="/"
                >
                  <img
                    src={logoImage}
                    alt="refresh"
                    className={classes.logo}
                  />
                </Link>
              </div>
            )
          }
          <div className={`${classes.right}`}>
            <AppBarCollapse iconSize={30} color='#FFF'>
              <Link
                color="inherit"
                variant="h6"
                underline="none"
                className={classes.rightLink}
                href="/services"
                style={{textTransform: 'capitalize'}}
              >
                {'Our Services'}
              </Link>
              <Link
                color="inherit"
                variant="h6"
                underline="none"
                className={classes.rightLink}
                style={{textTransform: 'capitalize'}}
                href="/about"
              >
                {'About'}
              </Link>
              <Link
                color="inherit"
                variant="h6"
                underline="none"
                className={classes.rightLink}
                style={{textTransform: 'capitalize'}}
                href="/contact"
              >
                {'Contact'}
              </Link>
              <Link
                color="inherit"
                variant="h6"
                underline="none"
                className={clsx(classes.rightLink, classes.linkSecondary)}
                style={{textTransform: 'capitalize'}}
                href="https://track.fyrasail.com"
              >
                {'Track'}
              </Link>
            </AppBarCollapse>
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
}

AppAppBar.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AppAppBar);
