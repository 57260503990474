import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Container from '@material-ui/core/Container';
import Typography from '../components/Typography';
import TextField from '../components/TextField';

import abstractBg from './../../../static/images/footerabstractbg.jpg';

function Copyright() {
  return (
    <React.Fragment>
      {'© '}
        Commonwealth Freight Services Pte Ltd. {new Date().getFullYear()} <br/>
        All Rights Reserved.
    </React.Fragment>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    position: 'relative',
    overflow: 'hidden',
    height: 150,
  },
  container: {
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(6),
    display: 'flex',
    zIndex: 1,
  },
  iconsWrapper: {
    height: 120,
  },
  icons: {
    display: 'flex',
  },
  icon: {
    width: 48,
    height: 48,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.warning.main,
    marginRight: theme.spacing(1),
    '&:hover': {
      backgroundColor: theme.palette.warning.dark,
    },
  },
  list: {
    margin: 0,
    listStyle: 'none',
    padding: 0,
  },
  listItem: {
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
  },
  language: {
    marginTop: theme.spacing(1),
    width: 150,
  },
  backgroundImage: {
    pointerEvents: 'none',
    position: 'absolute',
    width: '100%',
    height: '100%',
    opacity: 0.5,
  },
}));

export default function AppFooter() {
  const classes = useStyles();

  return (
    <Typography component="footer" className={classes.root}>
      <img
        src={abstractBg}
        className={classes.backgroundImage}
        alt="background"
      />
      <Container className={classes.container}>
        <Grid container spacing={5}>
          <Grid item xs={12} sm={4} md={4}>
            <Grid
              container
              direction="column"
              justifyContent="flex-end"
              className={classes.iconsWrapper}
              spacing={2}
            >
              <Grid item>
                <Copyright />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Typography>
  );
}
